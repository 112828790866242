import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Modal } from '../components/ui/Modal';
import isEmpty from 'lodash/isEmpty';

import {
  Table,
  THead,
  Th,
  TBody,
  Tr,
  Td,
  NoDataRow
} from '../components/common/Table';
import {
  activateGlobalOverlay,
  deactivateGlobalOverlay,
  setGlobalOverlayText,
  resetGlobalOverlayText
} from '../actions/overview';
import {
  getVolumesConnectorsAction,
  setVolumeCreationNoActionAction,
  getVolumeFromUrlSelector,
  getVolumesConnectorsSelector,
  editConfigGroupAction,
  deleteConfigGroupAction,
  getConfigGroupsAction,
  getConfigGroupFromUrl,
  getConfigGroupEditState,
  getConfigGroupDeletionState
} from '../ducks/volumes';
import {
  ContentContainer,
  Header,
  Breadcrumb as BreadcrumbStyle
} from '../components/ui/PageStructure';
import {
  ACTION_SUCCESS,
  ACTION_IN_PROGRESS,
  SFUSED_LOWER_BOUND_PORT,
  SFUSED_UPPER_BOUND_PORT,
  STATUS_THEME
} from '../constants';
import Breadcrumb from '../components/Breadcrumb';
import { InlineBreadcrumbItem, InlineStatus } from '../components/InlineStatus';
import { isValidNetworkIPDomain } from '../utils/helpers';
import HelpPopover from '../components/common/HelpPopover';
import SelectableConnectorsTable from '../components/SelectableConnectorsTable';
import SimpleTips from '../tips/SimpleTips';
import CGNFSSharesTips from '../tips/CGNFSSharesTips';

class EditConfigGroup extends Component {
  constructor() {
    super();

    this.state = {
      configGroup: null,
      volume: null,
      groupName: '',
      shareList: [],
      connectorList: [],

      availConnectors: [],
      linkConnectors: [],

      availableConnectors: [],
      selectedAvailConnectors: [],
      selectedlinkConnectors: [],

      linkedConnectors: [],

      attachedConnectors: [],
      selectedConnector: null,
      selectedOption: null,
      sharePath: '',
      network: '',

      expertMode: '',
      anonuid: null,
      anongid: null,
      deleteModalIsOpen: false,

      editShareIndex: -1,
      isConfigGroupLoaded: false,
      isVolumeConnectorsLoaded: false,

      removeAssociatedConnectorModalIsOpen: false
    };

    this.generateInitialData = this.generateInitialData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.moveConnAvailToLinked = this.moveConnAvailToLinked.bind(this);
    this.moveConnLinkedToAvail = this.moveConnLinkedToAvail.bind(this);
    this.deleteCurrentConfigGroup = this.deleteCurrentConfigGroup.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { configGroup } = this.props;
    this.props.getVolumesConnectors();

    if (!isEmpty(configGroup)) {
      // User arrive from the UI
      const state = this.generateInitialData(configGroup);
      this.setState(state);
    } else {
      // User arrive from the URL
      this.props.getConfigGroups();
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      volume,
      cgEditState,
      cgDeletionState,
      configGroup,
      volumesConnectors
    } = nextProps;

    if (cgEditState === ACTION_SUCCESS || cgDeletionState === ACTION_SUCCESS) {
      // Redirect
      let path = '/storages/file/';
      if (volume && volume.id) {
        path = `/storages/file/${volume.id}/config-group`;
      }
      this.props.history.push(path);
    }

    if (!this.state.isConfigGroupLoaded && !isEmpty(configGroup)) {
      const state = this.generateInitialData(configGroup);
      this.setState(state);
    }

    if (
      !this.state.isVolumeConnectorsLoaded &&
      volumesConnectors &&
      volumesConnectors.length > 0
    ) {
      this.setState({
        availableConnectors: volumesConnectors.filter(connector => {
          const connectorPortSplit =
            connector.address && connector.address.split(':');
          const connectorPort =
            (connectorPortSplit && connectorPortSplit[1]) || '-1';
          return (
            connector.detached === true &&
            connectorPort >= SFUSED_LOWER_BOUND_PORT &&
            connectorPort <= SFUSED_UPPER_BOUND_PORT
          );
        }),
        isVolumeConnectorsLoaded: true
      });
    }
  }

  componentWillUnmount() {
    this.props.deactivateGlobalOverlay();
    this.props.resetGlobalOverlayText();
  }

  generateInitialData(configGroup) {
    const cgConnectors = configGroup.connectors;

    const state = {
      groupName: configGroup.name,
      shareList: this.generateShares(configGroup),
      selectedConnector: configGroup.protocol,
      linkConnectors: cgConnectors,
      linkedConnectors: cgConnectors,
      configGroup,
      isConfigGroupLoaded: true
    };
    return state;
  }

  generateShares(configGroup) {
    if (
      configGroup &&
      configGroup.config &&
      configGroup.config.shares &&
      configGroup.config.shares.length
    ) {
      const shares = configGroup.config.shares.reduce(
        (prevShares, currShare) => {
          const share = currShare.hosts.map(item => {
            const keys = Object.keys(item.options || {});
            return {
              network: item.name,
              option: this.objectToShare(keys),
              expertMode: item.options_raw,
              sharePath: currShare.path,
              anonuid: item.options.anonuid,
              anongid: item.options.anongid
            };
          });
          return [...prevShares, ...share];
        },
        []
      );
      return shares;
    } else {
      return [];
    }
  }

  closeModal() {
    if (this.props.cgDeletionState !== ACTION_IN_PROGRESS) {
      this.setState({ deleteModalIsOpen: false });
    }
  }

  closeModalRemoveAssociatedConnector() {
    this.setState({ removeAssociatedConnectorModalIsOpen: false });
  }

  objectToShare(shares) {
    if (
      shares.length === 4 &&
      shares.find(s => s === 'rw') &&
      shares.find(s => s === 'all_squash') &&
      shares.find(s => s === 'anonuid') &&
      shares.find(s => s === 'anongid')
    ) {
      return 'map_all_users';
    }

    if (shares.length > 1) {
      return 'expert';
    }
    const share = shares[0];

    if (share === 'rw') {
      return 'default';
    }

    if (share === 'ro') {
      return 'read_only';
    }

    if (share === 'no_root_squash') {
      return 'allow_root_access';
    }

    return 'expert';
  }

  onSelectConnector(e) {
    let newState = { selectedConnector: e.target.value };
    this.setState(prevState => {
      if (
        newState.selectedConnector === 'localfs' ||
        newState.selectedConnector === 'cifs' ||
        newState.selectedConnector === 'cdmi'
      ) {
        newState = {
          ...prevState,
          ...newState,
          shareList: [],
          connectorList: [],
          selectedOption: null,
          sharePath: '',
          network: ''
        };
      }
      return newState;
    });
  }

  onSelectOptions(e) {
    this.setState({ selectedOption: e.target.value });
  }

  onConnectorCheckboxChange(selectedConnectorsState) {
    return (connectors, checked) => {
      if (checked) {
        this.setState(prevState => {
          let newSelectedConnectorsState = [
            ...prevState[selectedConnectorsState],
            ...connectors
          ];
          newSelectedConnectorsState = newSelectedConnectorsState.filter(
            (value, idx, array) => {
              return idx === array.findIndex(v => value.id === v.id);
            }
          );

          return {
            [selectedConnectorsState]: newSelectedConnectorsState
          };
        });
      } else {
        this.setState(prevState => {
          const connectorIds = connectors.map(c => c.id);
          return {
            [selectedConnectorsState]: prevState[
              selectedConnectorsState
            ].filter(connector => !connectorIds.includes(connector.id))
          };
        });
      }
    };
  }

  moveConnAvailToLinked() {
    this.setState(prevState => {
      return {
        availableConnectors: prevState.availableConnectors.filter(
          ac => !prevState.selectedAvailConnectors.find(sac => sac.id === ac.id)
        ),
        linkConnectors: [
          ...prevState.linkConnectors,
          ...prevState.selectedAvailConnectors
        ],
        selectedAvailConnectors: []
      };
    });
  }

  moveConnLinkedToAvail() {
    this.setState(prevState => {
      return {
        linkConnectors: prevState.linkConnectors.filter(
          lc => !prevState.selectedlinkConnectors.find(slc => slc.id === lc.id)
        ),
        availableConnectors: [
          ...prevState.availableConnectors,
          ...prevState.selectedlinkConnectors
        ],
        selectedlinkConnectors: []
      };
    });
  }

  updateConfigGroup() {
    const {
      shareList,
      linkConnectors,
      selectedConnector,
      groupName,
      configGroup
    } = this.state;
    const { volume } = this.props;

    const shareResult = shareList.reduce((prev, currShare) => {
      let config = {};
      switch (currShare.option) {
        case 'default':
          config = { access: 'rw' };
          break;
        case 'allow_root_access':
          config = { no_root_squash: null };
          break;
        case 'map_all_users':
          config = {
            access: 'rw',
            all_squash: null,
            anonuid: parseInt(currShare.anonuid, 10),
            anongid: parseInt(currShare.anongid, 10)
          };
          break;
        case 'read_only':
          config = { access: 'ro' };
          break;
        case 'expert':
          config = currShare.expertMode;
          break;
        default:
          config = { access: 'rw' };
      }

      const shareWithSamePathIndex = prev.findIndex(
        share => share.path === currShare.sharePath
      );

      if (shareWithSamePathIndex === -1) {
        return [
          ...prev,
          {
            path: currShare.sharePath,
            hosts: currShare.network.split(',').map(ip => {
              return currShare.option === 'expert'
                ? {
                    name: ip,
                    options_raw: config
                  }
                : {
                    name: ip,
                    options: config
                  };
            })
          }
        ];
      } else {
        let shareWithSamePath = prev[shareWithSamePathIndex];
        shareWithSamePath.hosts = [
          ...shareWithSamePath.hosts,
          ...currShare.network.split(',').map(ip => {
            return currShare.option === 'expert'
              ? {
                  name: ip,
                  options_raw: config
                }
              : {
                  name: ip,
                  options: config
                };
          })
        ];

        prev[shareWithSamePathIndex] = shareWithSamePath;
        return prev;
      }
    }, []);

    if (volume) {
      let result = {
        connectors: linkConnectors.map(c => c.id),
        protocol: selectedConnector,
        volume: volume.id,
        name: groupName
      };

      if (selectedConnector === 'nfs') {
        result.config = {
          shares: shareResult
        };
      }

      this.setState({ removeAssociatedConnectorModalIsOpen: false });
      this.props.setGlobalOverlayText('Editing configuration group...');
      this.props.activateGlobalOverlay();

      this.props.editConfigGroup(configGroup.id, result);
    }
  }

  submit(isRemovingAssociatedConnectors, e) {
    e.preventDefault();

    if (isRemovingAssociatedConnectors) {
      this.setState({ removeAssociatedConnectorModalIsOpen: true });
    } else {
      this.updateConfigGroup();
    }
  }

  removeShare(idx) {
    if (this.state.editShareIndex === -1) {
      this.setState(prevState => {
        return {
          shareList: prevState.shareList.filter(
            (share, shareIdx) => shareIdx !== idx
          )
        };
      });
    }
  }

  // Enter in Edit Mode
  enterEditShareMode(idx) {
    this.setState(prevState => {
      const selectedShare = prevState.shareList.find(
        (share, shareIdx) => shareIdx === idx
      );

      // This checking is only for EditConfigGroup because the back-end
      // return anonuid and anongid as a number
      const anonuid =
        typeof selectedShare.anonuid === 'number'
          ? selectedShare.anonuid.toString()
          : selectedShare.anonuid;
      const anongid =
        typeof selectedShare.anongid === 'number'
          ? selectedShare.anongid.toString()
          : selectedShare.anongid;

      const shareToEdit = {
        sharePath: selectedShare.sharePath,
        network: selectedShare.network,
        selectedOption: selectedShare.option,
        expertMode: selectedShare.expertMode,
        anonuid: anonuid,
        anongid: anongid
      };

      return {
        ...shareToEdit,
        editShareIndex: idx
      };
    });
  }

  exitEditShareMode() {
    this.setState({
      editShareIndex: -1,
      sharePath: '',
      network: '',
      expertMode: '',
      selectedOption: null,
      anonuid: null,
      anongid: null
    });
  }

  updateShare() {
    this.setState(prevState => {
      const newShareList = [...prevState.shareList];
      newShareList[prevState.editShareIndex] = {
        sharePath: prevState.sharePath,
        network: prevState.network,
        option: prevState.selectedOption,
        expertMode: prevState.expertMode,
        anonuid: prevState.anonuid,
        anongid: prevState.anongid
      };

      return {
        shareList: newShareList,
        editShareIndex: -1,
        sharePath: '',
        network: '',
        expertMode: '',
        selectedOption: null,
        anonuid: null,
        anongid: null
      };
    });
  }

  canUpdateShare() {
    const {
      shareList,
      sharePath,
      network,
      selectedOption,
      expertMode,
      anonuid,
      anongid,
      editShareIndex
    } = this.state;

    const duplicateCurrentShareIndex = shareList.findIndex(
      share => share.sharePath === sharePath && share.network === network
    );

    if (
      duplicateCurrentShareIndex !== -1 &&
      editShareIndex !== duplicateCurrentShareIndex
    ) {
      return false;
    }

    if (selectedOption === 'expert' && expertMode.length === 0) {
      return false;
    }

    if (
      selectedOption === 'map_all_users' &&
      (anonuid === null || anonuid === '' || anongid === null || anongid === '')
    ) {
      return false;
    }

    return (
      sharePath.length > 0 &&
      network.length > 0 &&
      selectedOption !== null &&
      !this.anonuidHasError() &&
      !this.anongidHasError() &&
      !this.clientNetworkIpHasError()
    );
  }

  canAddShare() {
    const {
      shareList,
      sharePath,
      network,
      selectedOption,
      expertMode,
      anonuid,
      anongid
    } = this.state;

    const duplicateCurrentShare = shareList.find(
      share => share.sharePath === sharePath && share.network === network
    );

    if (duplicateCurrentShare) {
      return false;
    }

    if (selectedOption === 'expert' && expertMode.length === 0) {
      return false;
    }

    if (
      selectedOption === 'map_all_users' &&
      (anonuid === null || anongid === null)
    ) {
      return false;
    }

    return (
      sharePath.length > 0 &&
      network.length > 0 &&
      selectedOption !== null &&
      !this.sharePathHasError() &&
      !this.clientNetworkIpHasError() &&
      !this.anonuidHasError() &&
      !this.anongidHasError()
    );
  }

  addShare(e) {
    e.preventDefault();
    this.setState(prevState => {
      return {
        shareList: [
          ...prevState.shareList,
          {
            sharePath: prevState.sharePath,
            network: prevState.network,
            option: prevState.selectedOption,
            expertMode: prevState.expertMode,
            anonuid: prevState.anonuid,
            anongid: prevState.anongid
          }
        ],
        sharePath: '',
        network: '',
        expertMode: '',
        selectedOption: null,
        anonuid: null,
        anongid: null
      };
    });
  }

  cgNameHasError() {
    const noSpaceRegex = /^\S*$/;
    const alphaNumericWithAtLeastOneLetterRegex = /\w*[a-zA-Z_-]\w*/;

    if (this.state.groupName.length === 0) {
      return null;
    }

    if (!this.state.groupName.match(noSpaceRegex)) {
      return 'Configuration Group Name cannot contain space';
    } else if (this.state.groupName.length > 64) {
      return 'Configuration Group Name must contain at most 64 characters.';
    } else if (
      !this.state.groupName.match(alphaNumericWithAtLeastOneLetterRegex)
    ) {
      return 'Configuration Group Name with number must have a character';
    } else {
      return null;
    }
  }

  sharePathHasError() {
    const { sharePath } = this.state;
    const regex = /^\/[\S]*$/;

    if (sharePath.length === 0) {
      return null;
    }

    if (!sharePath.match(regex)) {
      return 'Share Path should be a valid Unix path';
    } else {
      return null;
    }
  }

  clientNetworkIpHasError() {
    const { network } = this.state;

    if (network.length === 0) {
      return null;
    }

    if (!isValidNetworkIPDomain(network)) {
      return 'Client Network / IP should be a valid domain or IP';
    } else {
      return null;
    }
  }

  anonuidHasError() {
    const { anonuid } = this.state;
    const numberRegex = /^\d+$/;

    if (!anonuid || anonuid.length === 0) {
      return null;
    }

    if (!anonuid.match(numberRegex)) {
      return 'Anonuid must be a positive number';
    } else {
      return null;
    }
  }

  anongidHasError() {
    const { anongid } = this.state;
    const numberRegex = /^\d+$/;

    if (!anongid || anongid.length === 0) {
      return null;
    }

    if (!anongid.match(numberRegex)) {
      return 'Anongid must be a positive number';
    } else {
      return null;
    }
  }

  canSubmit() {
    if (
      this.state.selectedConnector === 'nfs' &&
      this.state.connectorList.length > 0 &&
      this.state.shareList.length === 0
    ) {
      return false;
    }

    return (
      this.state.groupName.length !== 0 &&
      this.state.selectedConnector !== null &&
      !this.cgNameHasError() &&
      !this.sharePathHasError() &&
      !this.clientNetworkIpHasError() &&
      !this.anonuidHasError() &&
      !this.anongidHasError()
    );
  }

  deleteCurrentConfigGroup(e) {
    e.preventDefault();
    if (!isEmpty(this.props.configGroup)) {
      this.props.setGlobalOverlayText('Deleting configuration group...');
      this.props.activateGlobalOverlay();
      this.closeModal();
      this.props.deleteConfigGroup(this.props.configGroup.id);
    }
  }

  render() {
    const { isAdmin, cgDeletionState, volume, configGroup } = this.props;
    const {
      shareList,
      editShareIndex,
      selectedAvailConnectors,
      selectedlinkConnectors
    } = this.state;
    const options = [
      { key: 'default', value: 'default', label: 'Default' },
      {
        key: 'allow_root_access',
        value: 'allow_root_access',
        label: 'Allow Root Access'
      },
      { key: 'map_all_users', value: 'map_all_users', label: 'Map All Users' },
      { key: 'read_only', value: 'read_only', label: 'Read-Only' },
      { key: 'expert', value: 'expert', label: 'Expert' }
    ];

    let tableRows = null;
    if (shareList.length === 0) {
      tableRows = <NoDataRow colSpan={4} text="No share created." />;
    } else {
      tableRows = shareList.map((row, idx) => {
        const optionName = options.find(option => option.key === row.option);

        const optionsDefinition = {
          default: 'rw',
          allow_root_access: 'no_root_squash',
          map_all_users: `rw,all_squash,anonuid=${row.anonuid},anongid=${row.anongid}`,
          read_only: 'ro',
          expert: row.expertMode
        };

        return (
          <Tr
            key={idx}
            style={{ color: editShareIndex === idx ? '#90caf9' : null }}
          >
            <Td fontSize="0.8em" color="white">
              {row.sharePath || ''}
            </Td>
            <Td fontSize="0.8em" color="white">
              {row.network || ''}
            </Td>
            <Td fontSize="0.8em" color="white">
              {`${optionName ? optionName.label : ''} (${
                optionsDefinition[row.option]
              })` || ''}
            </Td>
            {isAdmin ? (
              <Td
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                fontSize="0.8em"
                color="white"
              >
                <i
                  className="fa fa-edit fa-2x hover-icon"
                  style={{ marginRight: '10px' }}
                  onClick={this.enterEditShareMode.bind(this, idx)}
                />
                <i
                  className="fa fa-trash-o fa-2x hover-delete"
                  style={{
                    cursor: editShareIndex === -1 ? null : 'not-allowed'
                  }}
                  onClick={this.removeShare.bind(this, idx)}
                />
              </Td>
            ) : null}
          </Tr>
        );
      });
    }

    const connectorTypes = [
      { key: 'nfs', value: 'nfs', label: 'NFS' },
      { key: 'localfs', value: 'localfs', label: 'LOCALFS' },
      { key: 'cifs', value: 'cifs', label: 'SMB' },
      { key: 'cdmi', value: 'cdmi', label: 'CDMI' }
    ];

    const style = {
      bodyItems: {
        display: 'flex',
        flexDirection: 'column'
      },
      contentTitle: {
        textTransform: 'capitalize',
        fontSize: '16px',
        display: 'inline-block',
        paddingLeft: '10px',
        borderLeft: '2px solid #fff'
      },
      container: {
        marginBottom: '25px'
      },
      textField: { margin: '0 5px' },
      submitButtonContainer: {
        height: '40px',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
      }
    };

    const inputLabelPropsStyle = {
      style: { fontSize: '1.5rem', fontWeight: '100' },
      shrink: true
    };
    const inputPropsStyle = { style: { fontSize: '1.5rem' } };
    const helperTextProps = { style: { fontSize: '1rem' } };

    const canAddShare = this.canAddShare();
    const canUpdateShare = this.canUpdateShare();
    const canSubmit = this.canSubmit();

    const cgNameHasError = this.cgNameHasError();
    const sharePathHasError = this.sharePathHasError();
    const clientNetworkIpHasError = this.clientNetworkIpHasError();
    const anonuidHasError = this.anonuidHasError();
    const anongidHasError = this.anongidHasError();

    const isEditingConfigGroup = this.props.cgEditState === ACTION_IN_PROGRESS;
    const isDeletingConfigGroup = cgDeletionState === ACTION_IN_PROGRESS;
    let isSwitchToAssoDisabled = selectedAvailConnectors.length === 0;
    let isSwitchToAvailDisabled = selectedlinkConnectors.length === 0;

    const isRemovingAssociatedConnectors = !this.state.linkedConnectors.every(
      linkedConnector => {
        return this.state.linkConnectors.find(
          link => link.id === linkedConnector.id
        );
      }
    );

    return (
      <React.Fragment>
        <ContentContainer>
          <Header>
            <BreadcrumbStyle>
              <Breadcrumb
                routes={[
                  { name: 'VOLUMES', link: '/storages/file' },
                  {
                    name: volume && volume.name ? volume.name : '',
                    customName: volume ? (
                      <InlineBreadcrumbItem>
                        {volume.name}
                        <InlineStatus
                          status={volume.status}
                          text={volume.status}
                        />
                      </InlineBreadcrumbItem>
                    ) : null,
                    link: volume
                      ? `/storages/file/${volume.id}/config-group`
                      : '/storages/file'
                  },
                  {
                    name:
                      configGroup && configGroup.name
                        ? configGroup.name
                        : 'Configuration Group',
                    customName:
                      configGroup.name && configGroup.status ? (
                        <InlineBreadcrumbItem>
                          {configGroup.name}
                          <InlineStatus
                            status={configGroup.status}
                            text={configGroup.status}
                          />
                        </InlineBreadcrumbItem>
                      ) : null
                  }
                ]}
              />
            </BreadcrumbStyle>
          </Header>
          <BodyContainer>
            <div>
              <div style={style.bodyItems}>
                <Container>
                  <ContentTitle>Configuration Group Properties</ContentTitle>
                  <HelpPopover
                    content={
                      <SimpleTips
                        title="Configuration Group Properties"
                        content={`A configuration group requires a protocol (NFS/SMB/LocalFS/CDMI) and a name. Then, select one or more connectors associate to the configuration group.
    To associate or remove connectors, tick the connector box and click the right arrow to associate it to the configuration group, or click the left arrow to disassociate it.`}
                      />
                    }
                  />
                  <div
                    style={{ display: 'flex', width: '66%', marginTop: '15px' }}
                  >
                    <TextField
                      style={style.textField}
                      label="Configuration Group Protocol"
                      InputProps={inputPropsStyle}
                      InputLabelProps={inputLabelPropsStyle}
                      select
                      value={this.state.selectedConnector || ''}
                      onChange={this.onSelectConnector.bind(this)}
                      disabled
                      fullWidth
                      data-cy="select-config-group-protocol"
                    >
                      {connectorTypes.map(connectorType => (
                        <MenuItem
                          key={connectorType.key}
                          value={connectorType.value}
                          data-cy={
                            'select-config-group-protocol-item_' +
                            connectorType.label
                          }
                        >
                          {connectorType.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      style={style.textField}
                      label="Configuration Group Name"
                      placeholder="ConfigGroup1"
                      InputProps={inputPropsStyle}
                      InputLabelProps={inputLabelPropsStyle}
                      FormHelperTextProps={helperTextProps}
                      error={cgNameHasError !== null}
                      helperText={cgNameHasError}
                      value={this.state.groupName || ''}
                      onChange={e =>
                        this.setState({ groupName: e.target.value })
                      }
                      disabled={!isAdmin}
                      fullWidth
                      data-cy="textfield-config-group-name"
                    />
                  </div>
                </Container>
                {this.state.selectedConnector === 'nfs' ? (
                  <div style={style.container}>
                    <div style={isAdmin ? style.container : null}>
                      <div style={style.contentTitle}>NFS Shares</div>
                      <HelpPopover content={<CGNFSSharesTips />} />
                      <div>
                        <div style={{ marginTop: '15px' }}>
                          <Table>
                            <THead>
                              <Tr>
                                <Th width="200" fontSize="0.9em">
                                  Share Path
                                </Th>
                                <Th fontSize="0.9em">Client Network / IP</Th>
                                <Th fontSize="0.9em">Options</Th>
                                {isAdmin ? <Th width="70" /> : null}
                              </Tr>
                            </THead>
                            <TBody>{tableRows}</TBody>
                          </Table>
                        </div>
                      </div>
                      {isAdmin ? (
                        <React.Fragment>
                          <div
                            style={{
                              display: 'flex',
                              marginBottom: '10px',
                              marginTop: '15px'
                            }}
                          >
                            <TextField
                              style={style.textField}
                              label="Share Path"
                              placeholder={
                                '/volume/' +
                                (volume.name ? volume.name.toLowerCase() : '')
                              }
                              value={this.state.sharePath}
                              InputProps={inputPropsStyle}
                              InputLabelProps={inputLabelPropsStyle}
                              FormHelperTextProps={helperTextProps}
                              error={sharePathHasError !== null}
                              helperText={sharePathHasError}
                              onChange={e =>
                                this.setState({ sharePath: e.target.value })
                              }
                              fullWidth
                            />
                            <TextField
                              style={style.textField}
                              label="Client Network / IP"
                              placeholder="10.0.0.1"
                              value={this.state.network}
                              InputProps={inputPropsStyle}
                              InputLabelProps={inputLabelPropsStyle}
                              FormHelperTextProps={helperTextProps}
                              error={clientNetworkIpHasError !== null}
                              helperText={clientNetworkIpHasError}
                              onChange={e =>
                                this.setState({ network: e.target.value })
                              }
                              fullWidth
                            />
                            <TextField
                              style={style.textField}
                              label="Options"
                              InputProps={inputPropsStyle}
                              InputLabelProps={inputLabelPropsStyle}
                              select
                              value={this.state.selectedOption || ''}
                              onChange={this.onSelectOptions.bind(this)}
                              fullWidth
                            >
                              {options.map(option => (
                                <MenuItem key={option.key} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end'
                              }}
                            >
                              {editShareIndex === -1 ? (
                                <Button
                                  canSubmit={canAddShare}
                                  disabled={!canAddShare}
                                  onClick={this.addShare.bind(this)}
                                  style={{ padding: '7px 10px' }}
                                  data-cy="nfs-shares-add-button"
                                >
                                  Add
                                  <span
                                    className="fa fa-plus"
                                    style={{
                                      marginLeft: '8px',
                                      verticalAlign: 'middle'
                                    }}
                                  />
                                </Button>
                              ) : (
                                <React.Fragment>
                                  <CancelButton
                                    type="button"
                                    style={{
                                      padding: '7px 10px',
                                      marginRight: '10px'
                                    }}
                                    onClick={this.exitEditShareMode.bind(this)}
                                  >
                                    Cancel
                                  </CancelButton>
                                  <Button
                                    canSubmit={canUpdateShare}
                                    disabled={!canUpdateShare}
                                    onClick={this.updateShare.bind(this)}
                                    style={{ padding: '7px 10px' }}
                                  >
                                    Update
                                    <span
                                      className="fa fa-edit fa-lg"
                                      style={{
                                        marginLeft: '8px',
                                        verticalAlign: 'middle'
                                      }}
                                    />
                                  </Button>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                          {this.state.selectedOption === 'expert' ? (
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{ minWidth: '500px', maxWidth: '600px' }}
                              >
                                <TextField
                                  style={style.textField}
                                  label="Expert Options"
                                  placeholder="ro,all_squash,anonuid=65534,anongid=65534"
                                  value={this.state.expertMode || ''}
                                  InputProps={inputPropsStyle}
                                  InputLabelProps={inputLabelPropsStyle}
                                  onChange={e =>
                                    this.setState({
                                      expertMode: e.target.value
                                    })
                                  }
                                  fullWidth
                                />
                              </div>
                            </div>
                          ) : null}

                          {this.state.selectedOption === 'map_all_users' ? (
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  minWidth: '600px',
                                  maxWidth: '1000px'
                                }}
                              >
                                <TextField
                                  style={style.textField}
                                  label="Anonuid"
                                  placeholder="65534"
                                  value={
                                    this.state.anonuid == null
                                      ? ''
                                      : this.state.anonuid
                                  }
                                  InputProps={inputPropsStyle}
                                  InputLabelProps={inputLabelPropsStyle}
                                  FormHelperTextProps={helperTextProps}
                                  error={anonuidHasError !== null}
                                  helperText={anonuidHasError}
                                  onChange={e => {
                                    this.setState({ anonuid: e.target.value });
                                  }}
                                  fullWidth
                                />
                                <TextField
                                  style={style.textField}
                                  label="Anongid"
                                  placeholder="65534"
                                  value={
                                    this.state.anongid == null
                                      ? ''
                                      : this.state.anongid
                                  }
                                  InputProps={inputPropsStyle}
                                  InputLabelProps={inputLabelPropsStyle}
                                  FormHelperTextProps={helperTextProps}
                                  error={anongidHasError !== null}
                                  helperText={anongidHasError}
                                  onChange={e => {
                                    this.setState({ anongid: e.target.value });
                                  }}
                                  fullWidth
                                />
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {isAdmin ? (
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '47%' }}>
                      <div style={style.contentTitle}>Unused connectors</div>
                      <div style={{ marginTop: '15px' }}>
                        <SelectableConnectorsTable
                          style={style}
                          selectedConnectors={
                            this.state.selectedAvailConnectors
                          }
                          connectors={this.state.availableConnectors}
                          linkedConnectors={this.state.linkedConnectors}
                          onCheckboxChange={this.onConnectorCheckboxChange(
                            'selectedAvailConnectors'
                          )}
                          canSelect={isAdmin}
                          restrictDeadConnectors
                          emptyTableText="No Associated Connectors"
                        />
                        {isRemovingAssociatedConnectors ? (
                          <div
                            style={{
                              color: STATUS_THEME.orange,
                              fontSize: 12,
                              fontWeight: 'bold',
                              paddingLeft: '15px'
                            }}
                          >
                            Removing an associated Connector from a
                            Configuration group will erase its configuration.
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}
                    >
                      <Button
                        canSubmit={!isSwitchToAssoDisabled}
                        disabled={isSwitchToAssoDisabled}
                        style={{ padding: '7px 10px', marginBottom: '5px' }}
                        onClick={this.moveConnAvailToLinked}
                        data-cy="config-group-connector-add-button"
                      >
                        <span
                          className="fa fa-angle-right fa-lg"
                          style={{
                            verticalAlign: 'middle'
                          }}
                        />
                      </Button>
                      <Button
                        canSubmit={!isSwitchToAvailDisabled}
                        disabled={isSwitchToAvailDisabled}
                        style={{ padding: '7px 10px' }}
                        onClick={this.moveConnLinkedToAvail}
                      >
                        <span
                          className="fa fa-angle-left fa-lg"
                          style={{
                            verticalAlign: 'middle'
                          }}
                        />
                      </Button>
                    </div>
                    <div style={{ width: '47%' }}>
                      <div style={style.contentTitle}>
                        Associated Connectors
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <SelectableConnectorsTable
                          style={style}
                          connectors={this.state.linkConnectors}
                          selectedConnectors={this.state.selectedlinkConnectors}
                          linkedConnectors={this.state.linkedConnectors}
                          onCheckboxChange={this.onConnectorCheckboxChange(
                            'selectedlinkConnectors'
                          )}
                          canSelect={isAdmin}
                          emptyTableText="No Associated Connectors"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={style.contentTitle}>Associated Connectors</div>
                    <div style={{ marginTop: '15px' }}>
                      <SelectableConnectorsTable
                        style={style}
                        connectors={this.state.linkConnectors}
                        selectedConnectors={this.state.selectedlinkConnectors}
                        linkedConnectors={this.state.linkedConnectors}
                        onCheckboxChange={this.onConnectorCheckboxChange(
                          'selectedlinkConnectors'
                        )}
                        canSelect={isAdmin}
                        emptyTableText="No Associated Connectors"
                      />
                    </div>
                  </div>
                )}

                {isAdmin ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px'
                    }}
                  >
                    <DeleteButton
                      type="button"
                      canSubmit={!isDeletingConfigGroup}
                      disabled={isDeletingConfigGroup}
                      onClick={() => this.setState({ deleteModalIsOpen: true })}
                      data-cy="config-group-delete-button"
                    >
                      Delete
                      <i
                        className="fa fa-trash fa-lg"
                        style={{ margin: '5px 0 5px 10px' }}
                      />
                    </DeleteButton>
                    <div style={style.submitButtonContainer}>
                      <CancelButton
                        style={isAdmin ? { marginRight: '20px' } : null}
                        onClick={() => {
                          let path = '/storages/file/';
                          if (volume && volume.id) {
                            path = `/storages/file/${volume.id}/config-group`;
                          }
                          this.props.history.push(path);
                        }}
                      >
                        Discard Changes
                      </CancelButton>
                      <Button
                        canSubmit={canSubmit && !isEditingConfigGroup}
                        disabled={!canSubmit || isEditingConfigGroup}
                        onClick={this.submit.bind(
                          this,
                          isRemovingAssociatedConnectors
                        )}
                        data-cy="config-group-save-button"
                      >
                        Save
                        {isEditingConfigGroup ? (
                          <i
                            style={{ marginLeft: '5px' }}
                            className="fa fa-cog fa-spin fa-fw"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '10px' }}
                            className="fa fa-floppy-o fa-lg"
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </BodyContainer>
        </ContentContainer>
        <Modal
          isOpen={this.state.removeAssociatedConnectorModalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="onRequestClose Update Config Group"
          ariaHideApp={false}
          style={{
            content: {
              position: 'absolute',
              top: '25%',
              left: '30%',
              width: '40%'
            }
          }}
        >
          <div
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              color: STATUS_THEME.orange
            }}
          >
            Warning
          </div>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            You are initiating the removal of one or more connectors previously
            associated with the Configuration Group "{this.state.groupName}".
          </div>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            These connectors will lose their existing configurations upon
            clicking on "Yes, save".
          </div>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            This action may lead to a "conf mismatch" within the Configuration
            Group, potentially leading to a disruption of service.
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CancelButton
              style={{ padding: '10px 10px', margin: '0 10px' }}
              disabled={isEditingConfigGroup}
              onClick={this.closeModalRemoveAssociatedConnector.bind(this)}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              style={{ padding: '10px 10px', margin: '0 10px' }}
              canSubmit={!isEditingConfigGroup}
              disabled={isEditingConfigGroup}
              onClick={this.updateConfigGroup.bind(this)}
              data-cy="config-group-delete-modal-button"
            >
              Yes, save
              {isEditingConfigGroup ? (
                <i
                  style={{ marginLeft: '5px' }}
                  className="fa fa-cog fa-spin fa-fw"
                />
              ) : null}
            </DeleteButton>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.deleteModalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="onRequestClose Delete Config Group"
          ariaHideApp={false}
          style={{
            content: {
              position: 'absolute',
              top: '40%',
              left: '35%',
              width: '30%',
              height: '200px'
            }
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            Do you really want to delete {this.state.groupName} ?
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CancelButton
              style={{ padding: '10px 10px', margin: '0 10px' }}
              disabled={isDeletingConfigGroup}
              onClick={this.closeModal}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              style={{ padding: '10px 10px', margin: '0 10px' }}
              canSubmit={!isDeletingConfigGroup}
              disabled={isDeletingConfigGroup}
              onClick={this.deleteCurrentConfigGroup}
              data-cy="config-group-delete-modal-button"
            >
              Yes, delete
              {isDeletingConfigGroup ? (
                <i
                  style={{ marginLeft: '5px' }}
                  className="fa fa-cog fa-spin fa-fw"
                />
              ) : null}
            </DeleteButton>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const Container = styled.div`
  margin-bottom: 20px;
`;

const ContentTitle = styled.div`
  text-transform: capitalize;
  font-size: 16px;
  display: inline-block;
  padding-left: 10px;
  border-left: 2px solid #fff;
`;

const disabledColor = '#979797';
const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: ${props => (props.canSubmit ? '#ef3340' : disabledColor)};
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: ${props => (props.canSubmit ? '#d81e1e' : disabledColor)};
  }
`;

const CancelButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: #000;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: #3c3c3c;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: ${props => (props.canSubmit ? '#2b65ad' : disabledColor)};
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: ${props => (props.canSubmit ? '#205aa5' : disabledColor)};
  }
`;

const BodyContainer = styled.div`
  background-color: #201d1d;
  padding: 20px;
`;

const mapStateToProps = (state, ownProps) => ({
  isAdmin: state.get('overview').get('adminAccess'),
  volume: makeGetVolumeFromUrl(state, ownProps),
  configGroup: makeGetConfigGroupFromUrl(state, ownProps),
  volumesConnectors: getVolumesConnectorsSelector(state),
  cgEditState: getConfigGroupEditState(state),
  cgDeletionState: getConfigGroupDeletionState(state)
});

const mapDispatchToProps = dispatch => {
  return {
    getVolumesConnectors: () => dispatch(getVolumesConnectorsAction()),
    getConfigGroups: () => dispatch(getConfigGroupsAction()),
    editConfigGroup: (configGroupId, newConfigGroup) => {
      dispatch(editConfigGroupAction(configGroupId, newConfigGroup));
    },
    resetVolumeCreation: () => dispatch(setVolumeCreationNoActionAction()),
    deleteConfigGroup: id => dispatch(deleteConfigGroupAction(id)),
    activateGlobalOverlay: () => dispatch(activateGlobalOverlay()),
    deactivateGlobalOverlay: () => dispatch(deactivateGlobalOverlay()),
    setGlobalOverlayText: text => dispatch(setGlobalOverlayText(text)),
    resetGlobalOverlayText: () => dispatch(resetGlobalOverlayText())
  };
};

const makeGetVolumeFromUrl = createSelector(
  getVolumeFromUrlSelector,
  volume => volume
);

const makeGetConfigGroupFromUrl = createSelector(
  getConfigGroupFromUrl,
  configGroup => configGroup
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditConfigGroup));
