import { put, call, takeEvery, all } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import _ from 'lodash';
import { setInvalidBasicAuth } from '../actions/overview';
import { callApiPost } from '../utils/callApi';
import {
  floatStringsValueChecker,
  objectValueCheckerCreator
} from '../selectors/utils';

// Actions
const GET_TOP_SERVERS_SSD_READ = 'GET_TOP_SERVERS_SSD_READ';
const SET_TOP_SERVERS_SSD_READ = 'SET_TOP_SERVERS_SSD_READ';
const GET_TOP_SERVERS_SSD_WRITE = 'GET_TOP_SERVERS_SSD_WRITE';
const SET_TOP_SERVERS_SSD_WRITE = 'SET_TOP_SERVERS_SSD_WRITE';
const GET_TOP_SERVERS_READ = 'GET_TOP_SERVERS_READ';
const SET_TOP_SERVERS_READ = 'SET_TOP_SERVERS_READ';
const GET_TOP_SERVERS_WRITE = 'GET_TOP_SERVERS_WRITE';
const SET_TOP_SERVERS_WRITE = 'SET_TOP_SERVERS_WRITE';

// Reducer
const defaultState = fromJS({
  topServersSSDRead: [],
  topServersSSDWrite: [],
  topServersRead: [],
  topServersWrite: []
});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_TOP_SERVERS_SSD_READ:
      return state.set('topServersSSDRead', action.servers);
    case SET_TOP_SERVERS_SSD_WRITE:
      return state.set('topServersSSDWrite', action.servers);
    case SET_TOP_SERVERS_READ:
      return state.set('topServersRead', action.servers);
    case SET_TOP_SERVERS_WRITE:
      return state.set('topServersWrite', action.servers);
    default:
      return state;
  }
}

// Action Creators
function getTopServersSSDReadAction(timeFrame) {
  return {
    type: GET_TOP_SERVERS_SSD_READ,
    timeFrame
  };
}

function setTopServersSSDReadAction(servers) {
  return {
    type: SET_TOP_SERVERS_SSD_READ,
    servers
  };
}

function getTopServersSSDWriteAction(timeFrame) {
  return {
    type: GET_TOP_SERVERS_SSD_WRITE,
    timeFrame
  };
}

function setTopServersSSDWriteAction(servers) {
  return {
    type: SET_TOP_SERVERS_SSD_WRITE,
    servers
  };
}

function getTopServersReadAction(timeFrame) {
  return {
    type: GET_TOP_SERVERS_READ,
    timeFrame
  };
}

function setTopServersReadAction(servers) {
  return {
    type: SET_TOP_SERVERS_READ,
    servers
  };
}

function getTopServersWriteAction(timeFrame) {
  return {
    type: GET_TOP_SERVERS_WRITE,
    timeFrame
  };
}

function setTopServersWriteAction(servers) {
  return {
    type: SET_TOP_SERVERS_WRITE,
    servers
  };
}

// Sagas
function* getTopServers(timeFrame, type, operation, setTopServers) {
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            query_string: {
              query: `operation:${operation} AND diskname:*${type}*`
            }
          },
          {
            range: {
              '@timestamp': {
                gte: `now-${timeFrame}`
              }
            }
          }
        ]
      }
    },
    aggs: {
      agg_by_host: {
        terms: {
          field: 'ip',
          size: 500,
          min_doc_count: 1
        },
        aggs: {
          agg_by_range: {
            avg: {
              field: 'avg_ms'
            }
          }
        }
      }
    }
  };

  const requestURL = 'es_proxy/scality-stats-disk-*/_search';

  try {
    const response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    const topServers = response.aggregations.agg_by_host.buckets.map(host => {
      return {
        hostIp: host.key,
        value: host.agg_by_range.value.toFixed(2)
      };
    });

    yield put(
      setTopServers(
        _.sortBy(topServers, 'value')
          .reverse()
          .slice(0, 10)
      )
    );
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* getTopServersSSDRead({ timeFrame }) {
  yield call(
    getTopServers,
    timeFrame,
    'ssd',
    'send_data',
    setTopServersSSDReadAction
  );
}

function* getTopServersSSDWrite({ timeFrame }) {
  yield call(
    getTopServers,
    timeFrame,
    'ssd',
    '(receive_data OR get_data)',
    setTopServersSSDWriteAction
  );
}

function* getTopServersRead({ timeFrame }) {
  yield call(
    getTopServers,
    timeFrame,
    'disk',
    'send_data',
    setTopServersReadAction
  );
}

function* getTopServersWrite({ timeFrame }) {
  yield call(
    getTopServers,
    timeFrame,
    'disk',
    '(receive_data OR get_data)',
    setTopServersWriteAction
  );
}

function* topServersSaga() {
  yield all([
    takeEvery(GET_TOP_SERVERS_SSD_READ, getTopServersSSDRead),
    takeEvery(GET_TOP_SERVERS_SSD_WRITE, getTopServersSSDWrite),
    takeEvery(GET_TOP_SERVERS_READ, getTopServersRead),
    takeEvery(GET_TOP_SERVERS_WRITE, getTopServersWrite)
  ]);
}

// Selectors
const selectServers = state => state.get('servers').get('list');

const selectTopServersState = state => state.get('topServers');

const selectTopServersSSDReadState = state =>
  selectTopServersState(state).get('topServersSSDRead');

const selectTopServersSSDWriteState = state =>
  selectTopServersState(state).get('topServersSSDWrite');

const selectTopServersReadState = state =>
  selectTopServersState(state).get('topServersRead');

const selectTopServersWriteState = state =>
  selectTopServersState(state).get('topServersWrite');

const selectTopServersCreateSelector = selectServersState =>
  createSelector([selectServersState, selectServers], (topServers, servers) => {
    if (topServers && servers) {
      return topServers
        .map(topServer => {
          const selectedServer = servers.find(server =>
            server.ip_addresses.includes(topServer.hostIp)
          );
          return {
            ...topServer,
            serverName: selectedServer ? selectedServer.name : '',
            serverLink:
              selectedServer && selectedServer.id
                ? `/servers/${selectedServer.id}`
                : ''
          };
        })
        .sort(objectValueCheckerCreator(floatStringsValueChecker));
    } else {
      return [];
    }
  });

const selectTopServersSSDRead = selectTopServersCreateSelector(
  selectTopServersSSDReadState
);
const selectTopServersSSDWrite = selectTopServersCreateSelector(
  selectTopServersSSDWriteState
);
const selectTopServersRead = selectTopServersCreateSelector(
  selectTopServersReadState
);
const selectTopServersWrite = selectTopServersCreateSelector(
  selectTopServersWriteState
);

export {
  getTopServersSSDReadAction,
  setTopServersSSDReadAction,
  getTopServersSSDWriteAction,
  setTopServersSSDWriteAction,
  getTopServersReadAction,
  setTopServersReadAction,
  getTopServersWriteAction,
  setTopServersWriteAction,
  topServersSaga,
  selectServers,
  selectTopServersState,
  selectTopServersSSDRead,
  selectTopServersSSDWrite,
  selectTopServersRead,
  selectTopServersReadState,
  selectTopServersWrite,
  selectTopServersCreateSelector
};
