/**
 * Hardware details common to all servers
 */

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import HelpPopover from './common/HelpPopover';
import NetworkIcon from './common/icons/Network';
import CPUIcon from './common/icons/CPU';
import RAMIcon from './common/icons/RAM';
import HDDIcon from './common/icons/HDD';
import SSDIcon from './common/icons/SSD';
import ServerIcon from './common/icons/Server';
import RAIDIcon from './common/icons/RAID';
import BlockTitle from './ui/BlockTitle';
import SimpleTips from '../tips/SimpleTips';
import { prettifyBytes } from '../utils/helpers';

import { getBrandNameFromServerType } from '../utils/helpers';

import hpeLogo from '../assets/images/brand/hpe_mini.svg';
import dellLogo from '../assets/images/brand/dell.svg';
import ciscoLogo from '../assets/images/brand/cisco.svg';
import vmLogo from '../assets/images/brand/vm.svg';

const BaseImage = styled.img`
  max-height: 60px;
  max-width: 100%;
`;

const logoHPE = <BaseImage src={hpeLogo} height="45" alt="HPE" />;
const logoDELL = <BaseImage src={dellLogo} height="25" alt="DELL" />;
const logoCISCO = <BaseImage src={ciscoLogo} height="32" alt="CISCO" />;

const logoVM = (
  <BaseImage src={vmLogo} alt="VIRTUAL MACHINE" style={{ color: 'white' }} />
);

const logos = {
  HPE: logoHPE,
  DELL: logoDELL,
  CISCO: logoCISCO,
  VM: logoVM
};

/* Utility functions */

function prettyDisplay(number) {
  const { value, unit } = prettifyBytes(number);
  return `${value} ${unit}`;
}

function withDefault(variable, defaultValue = '<unknown>') {
  return variable || defaultValue;
}

function last_updated_text(date) {
  let when;
  if (date === null) {
    when = 'Never';
  } else {
    const then = moment(date);
    when = then.fromNow();
  }
  return `Last updated: ${when}.`;
}

/* Styled components */

const SectionHolder = styled.div`
  color: #a4acb4;
`;

const SubsectionTitle = styled(BlockTitle)`
  margin-top: 20px;
  color: #fff;
  &:first-child {
    margin-top: 0px;
  }
`;

const TopSubsection = styled.div`
  display: flex;
`;

const TopRight = styled.div`
  padding: 8px 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BrandImage = styled.div`
  flex: 1 0 auto;
`;

// Hold both Disks and RAID Controllers on one "row"
const StorageSubsection = styled.div`
  display: flex;
  & > ul {
    width: 50%;
  }
`;

const NICDetails = styled.div`
  padding: 10px 20px;
  font-size: 14px;
`;

const LabelText = styled.span`
  color: #fff;
  font-size: 12px;
  margin-right: 4px;
`;

/* MaterialUI styles */

const styles = theme => ({
  list: {
    minWidth: 300,
    width: '60%'
  },
  icon: {
    fontSize: 30
  },
  baseItem: {
    paddingTop: 2,
    paddingBottom: 2
  },
  panel: {
    margin: '10px 0',
    background: 'unset',
    border: 'unset',
    boxShadow: 'unset',
    '&:before': {
      background: 'unset'
    },
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.05)'
    }
  },
  panelSummary: {
    '& > div:first-child': {
      // Access the generated "content" div
      alignItems: 'center',
      margin: '12px 0'
    }
  },
  panelDetails: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    padding: '8px 24px'
  },
  roleChip: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '0 10px'
  },
  tooltip: {
    fontSize: 11
  }
});

/* Sub-components */

const DiskIcon = ({ type, ...props }) =>
  type === 'HDD' ? <HDDIcon {...props} /> : <SSDIcon {...props} />;

const NoData = ({ text = 'No data.' }) => (
  <Typography variant="subheading" style={{ padding: '12px 24px' }}>
    {text}
  </Typography>
);

const NICItem = ({ nic, classes, isVM = false }) => {
  const uniqueRoles = nic.ips.reduce((acc, ip) => {
    ip.roles.forEach(role => {
      if (!(role in acc)) acc.push(role);
    });
    return acc;
  }, []);

  return (
    <ExpansionPanel defaultExpanded className={classes.panel}>
      <ExpansionPanelSummary
        className={classes.panelSummary}
        expandIcon={<i className="fa fa-chevron-down" />}
      >
        <NetworkIcon className={classes.icon} />
        <ListItemText>
          {nic.iface}
          {uniqueRoles &&
            uniqueRoles.map((role, index) => (
              <Chip key={index} className={classes.roleChip} label={role} />
            ))}
        </ListItemText>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        <List
          className={classes.list}
          subheader={
            <ListSubheader
              component="div"
              style={{ fontSize: 12, lineHeight: '2em', color: '#fff' }}
            >
              IP Addresses
            </ListSubheader>
          }
        >
          {nic.ips.length === 0 ? (
            <NoData text="No IP address." />
          ) : (
            nic.ips.map(ip => (
              <ListItem key={ip.address} className={classes.baseItem}>
                <i className="fa fa-angle-right" />
                <ListItemText style={{ flex: 'none' }} primary={ip.address} />
              </ListItem>
            ))
          )}
        </List>
        <NICDetails>
          <LabelText>MAC Address</LabelText> {withDefault(nic.mac)}
          {(!isVM || nic.model) && (
            <React.Fragment>
              <br />
              <LabelText>Model</LabelText> {withDefault(nic.model)}
            </React.Fragment>
          )}
          {(!isVM || nic.speed) && (
            <React.Fragment>
              <br />
              <LabelText>Speed</LabelText>{' '}
              {nic.speed ? `${nic.speed} Gbits/s` : '<unknown>'}
            </React.Fragment>
          )}
        </NICDetails>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

/* Main component */

const HardwareCommon = props => {
  let {
    server_type,
    provided_model, // actually server.server_type
    cpu,
    cpu_count,
    ram,
    nics,
    disks,
    raid_controllers,
    last_update,
    ...rest
  } = props;

  /**
   * FIXME Quick fix to handle null value
   * `defaultProps` does not fill the default value if the value
   * is not undefined.
   */
  server_type = server_type || '';
  provided_model = provided_model || '';
  last_update = last_update || null;
  cpu = cpu || { max_freq: null, model: '' };
  cpu_count = cpu_count || 0;
  ram = ram || 0;
  nics = nics || [];
  disks = disks || [];
  raid_controllers = raid_controllers || [];

  const { classes } = rest;

  const brandName = getBrandNameFromServerType(server_type);
  const brandImage = logos[brandName] || '';
  const isVM = brandName === 'VM';

  const diskGroupMap = disks.reduce((acc, disk) => {
    const compositeKey = `${disk.model}-${prettyDisplay(disk.capacity)}`;
    if (compositeKey in acc) {
      acc[compositeKey].count += 1;
    } else {
      acc[compositeKey] = { count: 1, ...disk };
    }
    return acc;
  }, {});

  // Groups of disks (by Model/Size) are sorted in reverse order by their
  // total size
  const diskGroups = Object.values(diskGroupMap).sort((dg1, dg2) => {
    return dg2.count * dg2.capacity - dg1.count * dg1.capacity;
  });

  return (
    <SectionHolder>
      <Tooltip
        title={last_updated_text(last_update)}
        classes={{ tooltip: classes.tooltip }}
      >
        <span>
          <SubsectionTitle>Server Hardware</SubsectionTitle>
          <HelpPopover
            content={
              <SimpleTips
                title="Server Hardware"
                content={`Hardware details, dynamically updated (if not updated yet, shows
    information provided at installation).`}
              />
            }
          />
        </span>
      </Tooltip>
      <TopSubsection>
        <List className={classes.list}>
          <ListItem className={classes.baseItem}>
            <ServerIcon
              title="Server Model (detected)"
              className={classes.icon}
            />
            <ListItemText className={classes.root} primary={server_type} />
          </ListItem>
          <ListItem className={classes.baseItem}>
            <ServerIcon
              title="Server Type (manually defined)"
              className={classes.icon}
            />
            <ListItemText
              className={classes.root}
              primary={withDefault(provided_model, '<not provided>')}
            />
          </ListItem>
          <ListItem className={classes.baseItem}>
            <CPUIcon className={classes.icon} />
            <ListItemText
              primary={`${cpu_count} x ${withDefault(cpu.model)}`}
              secondary={
                (!isVM || cpu.max_freq) && (
                  <React.Fragment>
                    <LabelText>Max. Frequency</LabelText>
                    {cpu.max_freq
                      ? `${Number(cpu.max_freq / 1000000).toPrecision(2)} GHz`
                      : '<unknown>'}
                  </React.Fragment>
                )
              }
            />
          </ListItem>
          <ListItem className={classes.baseItem}>
            <RAMIcon className={classes.icon} />
            <ListItemText primary={prettyDisplay(ram)} />
          </ListItem>
        </List>
        <TopRight>
          <BrandImage>{brandImage}</BrandImage>
        </TopRight>
      </TopSubsection>
      <StorageSubsection>
        <List subheader={<SubsectionTitle>Disks</SubsectionTitle>}>
          {diskGroups.length === 0 ? (
            <NoData />
          ) : (
            diskGroups.map((diskGroup, index) => (
              <ListItem key={index}>
                <DiskIcon type={diskGroup.type} className={classes.icon} />
                <ListItemText
                  primary={`${diskGroup.count} x ${prettyDisplay(
                    diskGroup.capacity
                  )} | ${prettyDisplay(
                    diskGroup.count * diskGroup.capacity
                  )} TOTAL`}
                  secondary={
                    (!isVM || diskGroup.model) && (
                      <React.Fragment>
                        <LabelText>Model</LabelText>
                        {withDefault(diskGroup.model)}
                      </React.Fragment>
                    )
                  }
                />
              </ListItem>
            ))
          )}
        </List>
        <List subheader={<SubsectionTitle>RAID Controllers</SubsectionTitle>}>
          {raid_controllers.length === 0 ? (
            <NoData />
          ) : (
            raid_controllers.map((raid, index) => (
              <ListItem key={index}>
                <RAIDIcon className={classes.icon} />
                <ListItemText>
                  {withDefault(raid.model)}
                  {raid.cache && ` | ${prettyDisplay(raid.cache * 1000000000)} CACHE`}
                </ListItemText>
              </ListItem>
            ))
          )}
        </List>
      </StorageSubsection>
      <Tooltip
        title="Network interface Roles are shown in blue (DATA/MGMT/SVSD/S3)"
        classes={{ tooltip: classes.tooltip }}
      >
        <SubsectionTitle>Network Interfaces</SubsectionTitle>
      </Tooltip>
      {nics.length === 0 ? (
        <NoData />
      ) : (
        nics.map((nic, index) => (
          <NICItem key={index} nic={nic} classes={classes} isVM={isVM} />
        ))
      )}
    </SectionHolder>
  );
};

HardwareCommon.propTypes = {
  last_update: PropTypes.string.isRequired,
  provided_model: PropTypes.string.isRequired,
  server_type: PropTypes.string.isRequired,
  cpu: PropTypes.shape({
    max_freq: PropTypes.number,
    model: PropTypes.string
  }).isRequired,
  cpu_count: PropTypes.number.isRequired,
  ram: PropTypes.number.isRequired,
  nics: PropTypes.arrayOf(
    PropTypes.shape({
      iface: PropTypes.string,
      ips: PropTypes.arrayOf(
        PropTypes.shape({
          address: PropTypes.string,
          roles: PropTypes.arrayOf(PropTypes.string)
        })
      ),
      speed: PropTypes.number, // in Gbits/s
      vendor: PropTypes.string,
      model: PropTypes.string,
      mac: PropTypes.string
    })
  ).isRequired,
  disks: PropTypes.arrayOf(
    PropTypes.shape({
      capacity: PropTypes.number,
      model: PropTypes.string,
      type: PropTypes.string
    })
  ).isRequired,
  raid_controllers: PropTypes.arrayOf(
    PropTypes.shape({
      cache: PropTypes.number,
      model: PropTypes.string
    })
  ).isRequired
};

HardwareCommon.defaultProps = {
  server_type: '',
  provided_model: '',
  last_update: null,
  cpu: { max_freq: null, model: '' },
  cpu_count: 0,
  ram: 0,
  nics: [],
  disks: [],
  raid_controllers: []
};

export default withStyles(styles)(HardwareCommon);
