import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Modal } from '../components/ui/Modal';
import styled from 'styled-components';

import { HASH_KEY } from '../actions/global/constants';
import { logout } from '../actions/login';
import { userAccessConfigs } from '../actions/overview';
import AboutPopup from './AboutPopup';
import S3Management from './S3Management';
import {
  getOldSupPageLink,
  getMonitoringMainPageLink,
  getCloudMonitoringLink,
  extractUsername
} from '../utils/helpers';
import {
  updateS3ManagementAction,
  getS3StorageServicesAction,
  selectS3Storages,
  selectS3StorageInProgress
} from '../ducks/s3';
import * as cssVariables from '../components/ui/variables';
import dashboardIcon from '../assets/images/nav/dashboard-icon.png';
import monitoringIcon from '../assets/images/nav/stats-icon.png';
import operationIcon from '../assets/images/nav/config-icon.png';
import s3Icon from '../assets/images/nav/s3-service-icon.png';
import reportIcon from '../assets/images/nav/reports-icon.png';
import profileIcon from '../assets/images/nav/profile-icon.png';

const drkBg = '#141414';
const brdrClr = '#211d1d';

const NavItem = styled.div`
  position: relative;
  display: inline-block;
  width: 70px;
  height: 56px;
  border-left: 1px solid ${brdrClr};
  padding-bottom: 10px;

  cursor: ${props => (props.disable ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${drkBg};
    cursor: ${props => (props.disable ? 'not-allowed' : 'pointer')};
  }
`;

const NavItemTitle = styled.div`
  display: block;
  position: absolute;
  bottom: 6px;
  width: 100%;
  text-align: center;
  color: #76828f;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1;
`;

const HideableNavItemTitle = styled(NavItemTitle)`
  display: ${props => (props.hideOnHover ? 'block' : 'none')};
  ${NavItem}:hover & {
    display: ${props => (props.hideOnHover ? 'none' : 'block')};
  }
`;

const SubNav = styled.div`
  position: absolute;
  top: 56px;
  right: 0;
  width: auto;
  display: none;
  box-shadow: 0px 3px 7px #000;
  ${NavItem}:hover & {
    display: block;
  }
`;

const SubNavItem = styled.div`
  background: ${drkBg};
  border-bottom: 1px solid #191c1f;
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;

  a,
  div {
    text-decoration: none;
    padding: 22px 15px;
    display: block;
  }

  &:last-child {
    border: none;
  }
  &:hover {
    cursor: pointer;
    background: ${brdrClr};
  }
`;

const NavContainer = styled.div`
  display: flex;
`;

const NavIcon = styled.div`
  background: url(${({ icon }) => icon || dashboardIcon}) center no-repeat;
  width: 100%;
  height: 100%;
`;

const SwitchableNavItemTitle = ({ defaultTitle, onHoverTitle }) => {
  return (
    <React.Fragment>
      <HideableNavItemTitle hideOnHover>{defaultTitle}</HideableNavItemTitle>
      <HideableNavItemTitle>{onHoverTitle}</HideableNavItemTitle>
    </React.Fragment>
  );
};

export class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isS3ManagementPopup: false,
      s3Storage: null,
      isAboutPopupOpen: false,
      nos3Config: false,
      adminAccess: false,
      username: ''
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.s3ConfigError = this.s3ConfigError.bind(this);
    this.openAboutPopup = this.openAboutPopup.bind(this);
    this.closeAboutPopup = this.closeAboutPopup.bind(this);
    this.updateS3Management = this.updateS3Management.bind(this);
  }

  componentDidMount() {
    const hash_key = localStorage.getItem(HASH_KEY);
    const username = extractUsername(hash_key);

    this.setState({ username: username });
    this.props.getS3Storage();
    this.props.getUserAccessConfig();
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.s3storages)) {
      this.setState({ s3Storage: nextProps.s3storages[0] });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onLogOut(e) {
    e.preventDefault();

    const { logOut } = this.props;

    logOut();
    this.props.history.push('/login');
  }

  onS3ManagementPopup(isOpen) {
    this.props.getS3Storage();
    this.setState({
      isS3ManagementPopup: isOpen,
      isOpenS3ManagementTip: false
    });
  }

  updateS3Management(values) {
    const { s3Storage } = this.state;

    this.props.doUpdateS3Management(values, s3Storage.id);
    this.setState({ isS3ManagementPopup: false });
  }

  openAboutPopup() {
    this.setState({ isAboutPopupOpen: true });
  }

  closeAboutPopup() {
    this.setState({ isAboutPopupOpen: false });
  }

  s3ConfigError(isNos3Config) {
    this.setState({ nos3Config: isNos3Config });
  }

  render() {
    const {
      isS3ManagementPopup,
      s3Storage,
      isAboutPopupOpen,
      nos3Config,
      username
    } = this.state;
    const { adminAccess, s3storageInProgress } = this.props;

    let s3BrowserLinkComponent = null;

    if (s3Storage && s3Storage.s3browser_url) {
      s3BrowserLinkComponent = (
        <a
          href={s3Storage.s3browser_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          S3 Browser
        </a>
      );
    } else {
      s3BrowserLinkComponent = (
        <div onClick={() => this.s3ConfigError(!nos3Config)}>S3 Browser</div>
      );
    }

    const modalStyle = {
      content: {
        position: 'absolute',
        top: '10%',
        left: '35%',
        border: '0 !important',
        backgroundColor: `${cssVariables.drkBg} !important`,
        overflow: 'auto',
        borderRadius: '6px',
        outline: 'none',
        padding: '40px 15px 25px 45px !important',
        width: '680px',
        maxHeight: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    };

    return (
      <NavContainer ref={this.setWrapperRef}>
        <NavItem>
          <Link to="/">
            <NavIcon icon={dashboardIcon} />
          </Link>
          <NavItemTitle>Overview</NavItemTitle>
        </NavItem>
        <NavItem>
          <NavIcon icon={monitoringIcon} />
          <NavItemTitle>Monitoring</NavItemTitle>
          <SubNav>
            <SubNavItem>
              <a
                target="_blank"
                href={getMonitoringMainPageLink()}
                rel="noopener noreferrer"
              >
                Advanced Monitoring
              </a>
            </SubNavItem>
            <SubNavItem>
              <a
                target="_blank"
                href={getCloudMonitoringLink()}
                rel="noopener noreferrer"
              >
                Scality cloud Monitoring
              </a>
            </SubNavItem>
          </SubNav>
        </NavItem>
        <NavItem>
          <NavIcon icon={operationIcon}> </NavIcon>
          <NavItemTitle>Operations</NavItemTitle>
          <SubNav>
            {adminAccess ? (
              <SubNavItem>
                <div
                  onClick={() => this.onS3ManagementPopup(!isS3ManagementPopup)}
                >
                  S3 Service
                </div>
              </SubNavItem>
            ) : null}
            <SubNavItem>
              <Link to="/storages/file">Volumes</Link>
            </SubNavItem>
            {adminAccess ? (
              <SubNavItem>
                <a
                  target="_blank"
                  href={getOldSupPageLink()}
                  rel="noopener noreferrer"
                >
                  Rings
                </a>
              </SubNavItem>
            ) : null}
            <SubNavItem>
              <Link to="/zones">Zones</Link>
            </SubNavItem>
            <SubNavItem>
              <Link to="/servers">Servers</Link>
            </SubNavItem>
            <SubNavItem>
              <Link to="/disks">Disks</Link>
            </SubNavItem>
          </SubNav>
        </NavItem>
        <NavItem
          disable={
            s3Storage == null || s3Storage.cluster_health === 'NOT_DEPLOYED'
          }
        >
          <NavIcon icon={s3Icon} />
          <NavItemTitle>S3 Service</NavItemTitle>
          {s3Storage != null && s3Storage.cluster_health !== 'NOT_DEPLOYED' && (
            <SubNav>
              <SubNavItem>
                {!_.isNull(s3Storage.identisee_url) ? (
                  <a
                    target="_blank"
                    href={s3Storage.identisee_url}
                    rel="noopener noreferrer"
                  >
                    S3 Console
                  </a>
                ) : (
                  <div onClick={() => this.s3ConfigError(!nos3Config)}>
                    S3 Console
                  </div>
                )}
              </SubNavItem>
              <SubNavItem>{s3BrowserLinkComponent}</SubNavItem>
            </SubNav>
          )}
        </NavItem>
        <NavItem>
          <NavIcon icon={reportIcon} />
          <NavItemTitle>Help</NavItemTitle>
          <SubNav>
            <SubNavItem>
              <Link to="/documentation">Documentation</Link>
            </SubNavItem>
            <SubNavItem>
              <Link to="/support">Support</Link>
            </SubNavItem>
            {adminAccess ? (
              <SubNavItem>
                <a href="/apidoc/" target="_blank" rel="noopener noreferrer">
                  API
                </a>
              </SubNavItem>
            ) : null}
            <SubNavItem>
              <div onClick={this.openAboutPopup}>Version</div>
            </SubNavItem>
          </SubNav>
        </NavItem>
        <NavItem>
          <NavIcon icon={profileIcon} />
          <SwitchableNavItemTitle
            defaultTitle="Account"
            onHoverTitle={
              <React.Fragment>
                {username}
                <br />
                (role: {adminAccess ? 'admin' : 'user'})
              </React.Fragment>
            }
          />
          <SubNav>
            <SubNavItem>
              <div onClick={e => this.onLogOut(e)}>Logout</div>
            </SubNavItem>
          </SubNav>
        </NavItem>
        <Modal
          isOpen={isS3ManagementPopup}
          onRequestClose={() => this.onS3ManagementPopup(!isS3ManagementPopup)}
          shouldCloseOnOverlayClick={s3storageInProgress ? false : true}
          style={modalStyle}
        >
          {s3Storage === null ? (
            <p>Please wait...</p>
          ) : (
            <S3Management
              s3Storage={s3Storage}
              onSubmit={this.updateS3Management}
              closePopup={() => this.onS3ManagementPopup(!isS3ManagementPopup)}
            />
          )}
        </Modal>
        <Modal
          isOpen={nos3Config}
          onRequestClose={() => this.s3ConfigError(!nos3Config)}
          shouldCloseOnOverlayClick={true}
          style={modalStyle}
        >
          <p>No S3 Configurations found!</p>
        </Modal>
        <AboutPopup
          isAboutPopupOpen={isAboutPopupOpen}
          cancel={this.closeAboutPopup}
        />
      </NavContainer>
    );
  }
}

Nav.propTypes = {
  logOut: PropTypes.func,
  doUpdateS3Management: PropTypes.func,
  getS3Storage: PropTypes.func,
  s3storages: PropTypes.array,
  s3storageInProgress: PropTypes.bool,
  adminAccess: PropTypes.bool,
  getUserAccessConfig: PropTypes.func
};

Nav.defaultProps = {
  s3storages: [],
  s3storageInProgress: false,
  adminAccess: false
};

const mapDispatchToProps = function(dispatch) {
  return {
    logOut: () => dispatch(logout()),
    doUpdateS3Management: (data, storageId) =>
      dispatch(updateS3ManagementAction(data, storageId)),
    getS3Storage: () => dispatch(getS3StorageServicesAction()),
    getUserAccessConfig: () => dispatch(userAccessConfigs())
  };
};

const mapStateToProps = state => ({
  s3storages: selectS3Storages(state),
  s3storageInProgress: selectS3StorageInProgress(state),
  adminAccess: state.get('overview').get('adminAccess')
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));
